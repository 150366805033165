/* Footer */
@import url("https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css");
section {
  padding: 60px 0;
}

.opt-out-col {
  text-align: center;
  color: grey;
  margin-bottom: 3rem;
}
.opt-out {
  /* margin: auto; */
  font-size: 1rem;
  transition: 0.5s;
  margin: 2rem;
  padding: 0.25rem;
  border-radius: 0.5rem;
}

.opt-out:hover {
  cursor: pointer;
  background-color: grey;
  color: black;
  transition: 1.5s;
}

section .section-title {
  text-align: center;
  color: #222c37;
  margin-bottom: 50px;
  text-transform: uppercase;
}
#footer {
  margin-top: 0px;
  background: #222c37 !important;
}
#footer h5 {
  padding-left: 10px;
  border-left: 3px solid #eeeeee;
  padding-bottom: 6px;
  margin-bottom: 20px;
  color: #ffffff;
}
#footer a {
  color: #ffffff;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}
#footer ul.social li {
  padding: 3px 0;
}
#footer ul.social li a i {
  margin-right: 5px;
  font-size: 25px;
  -webkit-transition: 0.5s all ease;
  -moz-transition: 0.5s all ease;
  transition: 0.5s all ease;
}
#footer ul.social li:hover a i {
  font-size: 30px;
  margin-top: -10px;
}
#footer ul.social li a,
#footer ul.quick-links li a {
  color: #ffffff;
}
#footer ul.social li a:hover {
  color: #eeeeee;
}
#footer ul.quick-links li {
  padding: 3px 0;
  -webkit-transition: 0.5s all ease;
  -moz-transition: 0.5s all ease;
  transition: 0.5s all ease;
}
#footer ul.quick-links li:hover {
  padding: 3px 0;
  margin-left: 5px;
  font-weight: 700;
}
#footer ul.quick-links li a i {
  margin-right: 5px;
}
#footer ul.quick-links li:hover a i {
  font-weight: 700;
}

@media (max-width: 767px) {
  #footer h5 {
    padding-left: 0;
    border-left: transparent;
    padding-bottom: 0px;
    margin-bottom: 10px;
  }
}

#branding-section {
  text-align: center !important;
  background: #1d252d;
  color: rgba(255, 255, 255, 0.4);
  padding: 24px 0;
  text-transform: uppercase;
  font-size: 13px;
  display: block;
  margin: 0px;
}

.footer-branding-logo-col {
  margin: auto;
}

.footer-branding-logo {
  width: 4rem;
  font-size: 0.12em;
  display: inline-block;
  border-radius: 2px;
}

.nav-link-styles {
  font-size: 0.65em;
  font-style: normal;
}

.nav-link-styles:hover {
  text-decoration: underline white !important;
}

.footer-social-icon {
  width: 35px;
  margin: 8px;
  filter: gray; /* IE 9 */
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
}

.footer-social-icon:hover {
  -webkit-filter: grayscale(0);
  filter: grayscale(0);
  animation: shake 0.82s;
  transform: rotate(0deg);
}

.footer-row {
  margin: 0px;
}
@keyframes shake {
  10% {
    transform: rotate(5deg);
  }

  20%,
  80% {
    transform: rotate(0deg);
  }

  30%,
  50%,
  70% {
    transform: rotate(-5deg);
  }

  40%,
  60% {
    transform: rotate(0deg);
  }
}
