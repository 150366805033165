/* Green button */
.button-green {
  background-color: #3ba634;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  border-radius: 3px;
  margin-right: 0.5vw;
  margin-top: 0.3vw;
}

.button-green:hover {
  background-color: #429321;
}

.button-green-text {
  font-weight: 600;
  font-size: 1.1rem;
  color: #ffffff;
  letter-spacing: 0;
  text-shadow: 0 1px 0 #417505;
  vertical-align: -webkit-baseline-middle;
}

.button-green-text:hover {
  color: #ffffff;
}

/* Transparent white button */
.button-transparent {
  background-color: transparent;
  border-radius: 0.25rem;
  border: 0.1rem solid white;
  margin-right: 5%;
  margin-top: 0.3vw;
}

.button-transparent:hover {
  background-color: white;
}

.button-white-text {
  font-weight: 600;
  font-size: 1.1rem;
  color: #ffffff;
  letter-spacing: 0;
  text-shadow: 0 1px 0 #2a2a2a;
}

.button-white-text:hover {
  color: #2a2a2a;
}

.button-transparent-black {
  background-color: transparent;
  border-radius: 0.25rem;
  border: 0.1rem solid black;
  margin-right: 5%;
  margin-top: 0.3vw;
}

.button-transparent-black:hover {
  background-color: rgb(243, 243, 243);
}

.button-black-text {
  font-weight: 600;
  font-size: 1.1rem;
  color: black;
  letter-spacing: 0;
  text-shadow: 0 1px 0 #2a2a2a;
}

.button-black-text:hover {
  color: #222222;
}

.button-facebook {
  display: flex;
  justify-content: center;
  align-items: center;

  font-weight: 600;
  font-size: 1.1rem;
  color: white;
  letter-spacing: 0;
  text-shadow: 0 1px 0 rgba(103, 102, 103, 0.5);
  background-color: #1877f2 !important;
}

.button-facebook-round {
  width: 20%;
  display: inline;
  background-color: #1777f2;
  padding: 0.5rem 0.01rem;
  margin-left: 0.3rem;
  margin-right: 0.3rem;
  color: white;
  font-family: Helvetica, Arial, sans-serif;
  font-weight: bold;
  font-size: 0.9rem;
  border-radius: 1rem;
  cursor: pointer;
  vertical-align: middle;
}

.button-facebook-round > img {
  height: 1rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  vertical-align: middle;
  /* float: left; */
  display: inline;
}

.button-facebook-round > span {
  text-align: center;
  margin: auto;
  vertical-align: middle;
  padding-right: 0.5rem;
}

.link-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  display: inline;
  margin: 0;
  padding: 0;
  color: #007bff;
}

.button-zynga {
  display: flex;
  justify-content: center;
  align-items: center;

  font-weight: 600;
  font-size: 1.1rem;
  color: white;
  letter-spacing: 0;
  text-shadow: 0 1px 0 rgba(103, 102, 103, 0.5);
  background-color: #db0706 !important;
}

.button-logo {
  height: 3vh;
  vertical-align: middle;
  padding-left: 0.5vh;
  padding-right: 0.5vh;
}

.button-text-white {
  color: white;
  text-shadow: 0 1px 0 #2a2a2a;
  vertical-align: middle;
}

.signup-btn {
  margin-top: 10px !important;
  width: 92% !important;
}

/* Grey button */

.button-grey {
  background-color: #9a9797;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  border-radius: 3px;
  margin-right: 5%;
  margin-top: 0.3vw;
}

.button-grey:hover {
  background-color: #f3f3f3;
}

.button-grey-text {
  font-weight: 600;
  font-size: 1.1rem;
  color: white;
  letter-spacing: 0;
  text-shadow: 0 1px 0 rgba(103, 102, 103, 0.5);
}

.button-grey-text:hover {
  color: #4a4a4a;
  text-shadow: 0 1px 0 white;
}

/* Blue button */

.button-blue {
  background-color: #4579b9;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  border-radius: 3px;
  margin-right: 5%;
  margin-top: 0.3vw;
}

.button-blue:hover {
  background-color: #5298c1;
}

.button-blue-text {
  font-weight: 600;
  font-size: 1.1rem;
  color: #ffffff;
  letter-spacing: 0;
  text-shadow: 0 1px 0 #417505;
}

.button-blue-text:hover {
  color: #ffffff;
}

.restore-farm-button {
  /* add in extra class of action button */
  background-color: darkcyan;
}

@media screen and (min-width: 1440px) {
  .button-green {
    margin-top: -0.2vw;
  }

  .button-grey {
    margin-top: -0.2vw;
  }

  .button-blue {
    margin-top: -0.2vw;
  }
}

/** Mobile, iphoneX, pixel 2 */
@media screen and (max-width: 600px) {
  .button-green {
  }

  .button-grey {
  }

  .button-blue {
  }
}

/** Mobile, iphoneX, pixel 2 */
@media screen and (max-width: 321px) {
  .button-green {
    margin-right: 1.4vw;
    margin-top: 0.1vw;
  }

  .button-grey {
    margin-right: 1.4vw;
    margin-top: 0.1vw;
  }

  .button-blue {
    margin-right: 1.4vw;
    margin-top: 0.1vw;
  }

  .button-zynga {
    margin-right: 1.4vw;
    margin-top: 0.1vw;
  }

  .button-facebook {
    margin-right: 1.4vw;
    margin-top: 0.1vw;
  }
}
