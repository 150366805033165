@import url("https://fonts.googleapis.com/css?family=PT+Sans");

body {
  font-family: "PT Sans";
}

html {
  scrollbar-width: thin;
  scrollbar-color: rgb(170, 170, 170) transparent;
  scroll-behavior: smooth;
}

body::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 1px;
	background-color: transparent;
}

body::-webkit-scrollbar
{
	width: 2px;
	background-color: transparent;
}

body::-webkit-scrollbar-thumb
{
	border-radius: 2px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: rgb(170, 170, 170);
}

.hz-text-align {
  text-align: center;
}

.rounded-corners {
  border-radius: 5px;
}

.rounded-corners-top {
  border-radius: 5px 5px 0px 0px;
}

.rounded-corners-bottom {
  border-radius: 0px 0px 5px 5px;
}

.rounded-corners-top-neg {
  border-radius: -5px -5px 0px 0px;
}

.rounded-corners-bottom-neg {
  border-radius: 0px 0px -5px -5px;
}

.rounded-corners-top {
  border-radius: 5px 5px 0px 0px;
}

.rounded-corners-bottom {
  border-radius: 0px 0px 5px 5px;
}

.rounded-corners-top-neg {
  border-radius: -5px -5px 0px 0px;
}

.rounded-corners-bottom-neg {
  border-radius: 0px 0px -5px -5px;
}

.modal-head {
  background-color: #222c37;
  color: white;
}

.debugging-border {
  border: solid red 2px;
}
.debugging-border-green {
  border: solid green 2px;
}
.debugging-border-blue {
  border: solid blue 2px;
}
.debugging-border-yellow {
  border: solid yellow 2px;
}

.div-button {
  cursor: pointer;
}

.auto-margin {
  margin: auto;
}

.text-center {
  text-align: center;
}

.equally-spaced {
  margin-left: auto;
  margin-right: auto;
}

.equally-spaced-top {
  margin-top: auto;
  margin-bottom: auto;
}

.zero-pad {
  padding-left: 0px;
  padding-right: 0px;
}

.zero-pad-l {
  padding-left: 0px;
}

.zero-pad-r {
  padding-right: 0px;
}

.zero-pad-t {
  padding-top: 0px;
}

.zero-pad-b {
  padding-bottom: 0px;
}

.zero-margin-l {
  margin-left: 0px;
}

.zero-margin-r {
  margin-right: 0px !important ;
}

.zero-margin-t {
  margin-top: 0px;
}

.zero-margin-b {
  margin-bottom: 0px;
}

.text-align-l {
  text-align: left;
}

.text-align-r {
  text-align: right;
}

.soft-pad {
  padding: 5% 0px 5% 0px;
}

.transparent {
  opacity: 0;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

/* Form validation */
.inp-error {
  animation: shake 0.2s ease-in-out 0s 2;
  box-shadow: 0 0 0.5em red;
  border-radius: 5px;
}
