.farm2-pageroot {
  color: inherit;
  font-family: "PT Sans";
  font-weight: 400;
  line-height: 1.2;
}

.farm2-pageroot-font {
  font-size: 2.25rem;
}
