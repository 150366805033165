.menu-icon-styles {
  width: 2.6rem;
  padding: 0.2rem;
  display: inline-block;
}

.menu-wrap-text-styles {
  text-decoration: none;
  color: #222c37;
  font-size: 1.3rem;
  vertical-align: middle;
}
.input-text-below{
  font-size: 0.8rem;
  color: white !important;
}
.fs-i{
  font-style: italic !important;
}
.ai-fs{
  align-items: flex-start !important;
}
.vl-b{
  vertical-align: bottom !important;
}
.display-inline-b{
  display: inline-block !important;
}
.fb-connect-btn {
  font-size: 0.8rem;
}
.pb-5p3{
padding-bottom:5.3px !important ;
}
.mr-14{
  margin-right: 14px !important;
}
.btn-separator{
  border-right: 1px solid white;
}
.display-contents{
  display: contents !important;
}
.nav-fa-icon {
  width: 2.6rem !important;
  padding-right: 1.5rem !important;
  vertical-align: middle !important;
}
.btn-fb {
  font-weight: 600;
  color: white;
  letter-spacing: 0;
  text-shadow: 0 1px 0 rgba(103, 102, 103, 0.5);
  background-color: #4a6ea9 !important;
}

.btn-fb:hover{
  background-color: #365899 !important;
}

.btn-fb-text{
  font-size: 0.8rem;
  display: inline-block;
  vertical-align: text-bottom;
}
.fa-facebook-f.fb-hover:hover{
  color: white !important;
}
.dropdown-menu-customized {
  margin-top: 2vw;
  margin-right: -20px;
}

.external-link{
  font-size: 0.7rem !important;
  position: absolute;
  margin-left: -3px;
  color: white;
  margin-top: 5px;
}

.white-seperator{
  border-left:white 1px solid;
  height: 48px;
  vertical-align: -webkit-baseline-middle;
  margin-left: 5px;
  box-sizing: border-box;
  display:inline-block;
}

.nav-link.nav-link-style:hover > .nav-fa-icon {
  color: #3f51b5;
}

.nav-link.nav-link-style:hover {
  background-color: #EAEAEA;
}

/********************************* 
 * Desktop navigation styles
 *********************************/

.navbar-custom {
  /* padding-bottom: 2.5vw; */
  /* padding-top: 23px; */
  padding-right: 0px !important;
  margin-right: 0px !important;
}

.nav-l-icons {
  display: inline-block;
  position: relative;
  /* padding-top: 23px; */
}

.nav-r-icons {
  margin-top: auto;
  margin-bottom: auto;
}

.zynga-logo-branding {
  font-size: 3rem !important;
  left: 70px;
  bottom: 10px;
  height: 70px;
  background-size: auto 70px;
}

.exp-coupon-image {
  display: inline-block;
  height: 70px;
  width: 70px;
  position: relative;
}

.exp-coupon-text {
  display: inline-block;
  font-size: 0.7rem;
  color: yellow;
  text-align: center;
  width: 90px;
  position: relative;
}

.exp-coupon-image-right {
  display: inline-block;
  height: 70px;
  width: 70px;
  position: relative;
}

.exp-coupon-text-right {
  display: inline-block;
  font-size: 0.7rem;
  color: yellow;
  text-align: center;
  width: 90px;
  position: relative;
}

.branding-hover-hide {
  transform: scale(0);
  transition-duration: 0.4s;
  z-index: 2;
  display: inline-block;
}

.branding-hover-show {
  transform: scale(1);
  transition-duration: 0.4s;
  z-index: 3;
  display: inline-block;
}

.zynga-logo-branding-container {
  /* width: 4vw; */
  text-align: center;
  margin-top: auto;
  margin-bottom: auto;
  padding: 0.25vw;
}

.option-elements {
  margin-right: 10px;
}

.option-elements {
  margin-right: 1vw;
}

.navbar-brand {
  position: relative;
  background-color: #dc0606;
  width: 250px;
  padding-top: 20px;
}

.connectOptions {
}

.connect-user-state-loggedin {
}

.connect-user-state-loggedout {
}

.connect-user-actions-login-signup {
}

.connect-user-actions-logout {
}

.not-loggedin-welcome {
  font-weight: 400;
  font-size: 2.5vw;
  color: white;
  display: inline-block;
}

.connect-user-state-loggedin-picture {
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 25px;
  width: 40px;
  height: 40px;
}

.loggedin-options-dropdown {
  margin-top: 7%;
}

/** Localizer button */
.localizer-toggle {
  cursor: pointer;
  background-color: #4990e2;
  font-weight: 600;
  font-size: 2vw;
  color: #ffffff;
  width: 4vw;
  height: 4vw;
  border-radius: 2vw;
  text-align: center;
  padding-top: 0.9vw;

  background-position: center;
  background-size: 2vw 2vw;
  background-repeat: no-repeat;
  animation: localizer-anim 16s infinite;
}

.fa:hover{
  color: #4FA035 !important;
}

/* logged in btn */
.loggedin-button {
  cursor: pointer;
  color: #ffffff;
  padding-top: 15px;
}

.nav-buttons:hover {
  background-color: #4FA035;
  color: #ffffff;
  border-radius: 10px;
}

.nav-center {
  vertical-align: middle !important;
  padding-right: 4vw;
  /* text-decoration: none !important; */
}

.tos-bottom {
  position: absolute;
  bottom: -15px;
}

.home {
  font-size: 2.5rem !important;
  padding: 0 1.1rem;
}

.community {
  font-size: 2rem !important;
  padding: 0.1rem 0.3rem;
}

.fs-inherit {
  font-size: inherit;
}

.loginSection {
  margin-top: 20px;
}

/* home btn */
.home-button {
  color: white !important;
  vertical-align: middle !important;
  z-index: 1;
  text-decoration: none !important;
}

/* Notification bell */
.notification-bell {
  color: white !important;
  vertical-align: middle !important;
  font-size: 2rem !important;
  z-index: 1;
  margin-top: 15px;
}

.notification-number {
  z-index: 2;
  vertical-align: top !important;
  margin-left: -30%;
  margin-top: 15px;
}

.no-cross-img {
  background-image: none !important;
}

@keyframes localizer-anim {
  0% {
    background-image: none;
    color: #ffffff;
    /* -webkit-transition: 2s ease; */
    /* transition: 2s ease; */
  }
  25% {
    background-image: url("https://zlive.cdn.zynga.com/images/translations/translation_icon_100.png");
    color: transparent;
    /* -webkit-transition: 2s ease; */
    /* transition: 2s ease; */
  }
  50% {
    background-image: none;
    color: #ffffff;
    /* -webkit-transition: 2s ease; */
    /* transition: 2s ease; */
  }
  100% {
    background-image: url("https://zlive.cdn.zynga.com/images/translations/translation_icon_100.png");
    color: transparent;
    /* -webkit-transition: 2s ease; */
    /* transition: 2s ease; */
  }
}

@media screen and (min-width: 1441px) {
  /* .menu-icon-styles {
    width: 4vw;
    padding: 0.85vw 0.5vw 0.5vw 0.5vw;
    display: inline-block;
  } */

  /* .menu-wrap-text-styles {
    font-size: 1.2vw;
  } */

  .navbar-custom {
    padding-bottom: 0vw;
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.14);
  }

  .nav-r-icons {
    margin-top: auto;
    margin-bottom: auto;
    /* padding-right: 0px; */
  }

  .zynga-logo-branding-container {
    /* width: 4vw; */
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .zynga-logo-branding {
    margin-top: auto;
    margin-bottom: auto;
  }

  .localizer-toggle {
    font-size: 1.8vw;
    width: 3vw;
    height: 3vw;
    padding-top: 0.5vw;
    border-radius: 1.5vw;

    background-position: center;
    background-size: 2vw 2vw;
    background-repeat: no-repeat;
    animation: localizer-anim 16s infinite;
    -webkit-transition: 1s ease;
    transition: 1s ease;
  }

  .not-loggedin-welcome {
    font-size: 2vw;
    line-height: 2.25vw;
  }

  .option-elements {
    padding: -0.9vw;
  }
}

/** Mobile, iphoneX, pixel 2 */
@media screen and (max-width: 600px) {
  /* .menu-icon-styles {
    width: 7vw;
    padding: 0.5vh 0.5vw 0.5vh 0.5vw;
    display: inline-block;
  } */

  /* .menu-wrap-text-styles {
    font-size: 3vw !important;
  } */

  .zynga-logo-branding {
    /* width: 8vw;
    height: 8vw; */
    /* margin-left: 4vw; */
  }

  .localizer-toggle {
    font-size: 4vw;
    width: 8vw;
    height: 8vw;
    padding-top: 2vw;
    border-radius: 4vw;

    background-position: center;
    background-size: 6.5vw 6.5vw;
    background-repeat: no-repeat;
    animation: localizer-anim 16s infinite;
    -webkit-transition: 1s ease;
    transition: 1s ease;
  }

  .not-loggedin-welcome {
    font-size: 4vw;
    line-height: 4vw;
    display: none;
  }

  .option-elements {
    padding: -0.1vw;
  }

  /* Notification bell */
  .notification-bell {
    font-size: 1.5rem !important;
  }

  .tos-bottom {
    position: static;
    bottom: -15px;
  }

}
