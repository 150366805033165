.survey-dialog {
  background-color: #222c37;
  color: white;
  font-size: 1rem !important;
}

.survey-close-btn {
  position: absolute;
  z-index: 2;
  right: 0;
  margin-right: 0.5vw;
  margin-top: 0.5vw;
}

#beta-carousel {
  margin-top: 1rem;
  padding: 2rem;
  border: 1px grey solid;
  border-radius: 2px;
}

.survey-radio-options {
  margin-left: 0.9rem;
  vertical-align: middle;
  margin-top: auto;
  margin-bottom: auto;
  cursor: pointer;
}

.survey-input-hole {
  cursor: pointer;
}
