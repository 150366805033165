.info-modal-body {
    padding: 0;
}
  
.info-header {
    font-size: 2vw;
}

.reward-info-text {
    color: white;
    display: block;
    text-align: center;
}

.info-container {
    z-index: 100;
    position: absolute;
    margin-left: 55px;
    margin-top: 15px;
}

.info-subheader {
    font-size: 1.1vw;
    margin-top: 10px;
    color: darkgray;
}

.info-timer {
    color: #000000;
    font-size: 1.2vw;
    margin-top: 30px;
}

.info-close {
    font-size: 2.0rem;
    margin-right: 0px !important;
    opacity: 1;
}

.reward-card {
    height: 150px;
    width: 150px
}

.rewards-scrollable-container {
  margin-top: 30px;
  max-height: 530px;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: thin;
  scrollbar-color: rgb(170, 170, 170) transparent;
}

.rewards-scrollable-container::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 1px;
  background-color: transparent;
}

.rewards-scrollable-container::-webkit-scrollbar {
  width: 5px;
  background-color: rgb(45, 44, 44) lightgray;
}

.rewards-scrollable-container::-webkit-scrollbar-thumb {
  border-radius: 2px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color:  rgb(99, 97, 97);
}

.grid-container {
    display: grid;
    grid-template-columns: 150px 150px 150px 150px;
    grid-gap: 5px;
    padding: 10px 25px;
  }
  
.grid-container > div {
    padding: 0px 0;
}

.reward-slot-bg {
    position: relative;
    color: black;
}

.reward-slot-contents {
    position: absolute;
    top: 8%;
    left: 18%;
    font-family: 'PT Sans';
}

.reward-slot-text {
    font-size: 18px;
    text-align: center;
    margin-block-end: 0px !important;
}

.v2-btn {
    width: 300px;
    margin-left: 180px;
    margin-top: 26px;
}

.v1-btn {
    width: 300px;
    margin-left: 180px;
    margin-top: 14vh;
}

.v1-container .row {
    margin-top:20px;
    margin-bottom: 20px;
    text-align:center;
}
.v1-container .row img {
    margin:0 20px;
    height: 200px;
    width: 200px;
}

.img-container {
    position: relative;
}

.img-animate-1 {
    animation: test 1s ease-in forwards;
}

@keyframes test {
    from { 
        transform: translate(200px,100px) scale(0);
    }
    to   { 
        transform: translate(0px,0px) scale(1);
    }
}

.img-animate-2 {
    opacity: 0;
    animation: test2 1s ease-in forwards 1s;
}

@keyframes test2 {
    from { 
        opacity: 0;
        transform: translate(200px,300px) scale(0);
    }
    to   { 
        opacity: 1;
        transform: translate(0px,0px) scale(1);
    }
}

.img-animate-3 {
    animation: test3 1s ease-in forwards 2s;
    opacity: 0;
}

@keyframes test3 {
    from { 
        opacity: 0;
        transform: translate(100px,400px) scale(0);
    }
    to   { 
        opacity: 1;
        transform: translate(0px,0px) scale(1);
    }
}

.img-animate-4 {
    animation: test4 1s ease-in forwards 3s;
    opacity: 0;
}

@keyframes test4 {
    from { 
        opacity: 0;
        transform: translate(-50px,300px) scale(0);
    }
    to   { 
        opacity: 1;
        transform: translate(0px,0px) scale(1);
    }
}

.img-animate-5 {
    animation: test5 1s ease-in forwards 4s;
    opacity: 0;
}

@keyframes test5 {
    from { 
        opacity: 0;
        transform: translate(-200px,160px) scale(0);
    }
    to   { 
        opacity: 1;
        transform: translate(0px,0px) scale(1);
    }
}

.check-mark {
    position: absolute;
    height: 60px;
    left: 20px;
    bottom: 30px;
}

.reward-name {
    color: white;
    font-size: 14px;
    margin-top: -28px;
    background-color: rgba(140,99,25,0.6);
    text-align: center;
    z-index: 100;
    position: relative;
    width: 140px;
    margin-left: 4px;
}