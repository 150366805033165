.scroll_helper_common {
  position: fixed;
  right: 1rem;
  width: 2rem;
  height: 2rem;
  z-index: 99999;
  background-color: white;
  border-radius: 0.5rem;
  cursor: pointer;
}

.scroll_helper_up {
  bottom: 3.1rem;
  transform: rotate(180deg);
  cursor: pointer;
}

.scroll_helper_down {
  bottom: 1rem;
  cursor: pointer;
}

.scroll_helper_up:hover {
  background-color: rgba(64, 133, 112, 0.541);
}

.scroll_helper_down:hover {
  background-color: rgba(64, 133, 112, 0.541);
}
