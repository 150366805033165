.wrapper {
  /* background: wheat; */
  background: transparent;
  width: 15rem;
  display: flex;
  flex-direction: column;
}
.wrapper-cell {
  display: flex;
  margin-bottom: 0.25rem;
  margin-top: 0.25rem;
}
@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
.animated-background,
.image,
.text-line-short,
.text-line-med,
.text-line-long {
  animation-duration: 1.25s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: transparent;
  background: linear-gradient(
    to right,
    transparent 8%,
    rgb(57, 66, 77) 18%,
    transparent 33%
  );
  background-size: 800px 104px;
  height: 96px;
  position: relative;
}
.image {
  height: 2rem;
  width: 2rem;
}
.text {
  margin-left: 1rem;
}
.text-line-short {
  height: 0.5rem;
  width: 5rem;
  margin: 0.25rem 0;
  border-radius: 0.25rem;
}
.text-line-med {
  height: 0.5rem;
  width: 8rem;
  margin: 0.25rem 0;
  border-radius: 0.25rem;
}
.text-line-long {
  height: 0.5rem;
  width: 12rem;
  margin: 0.25rem 0;
  border-radius: 0.25rem;
}
