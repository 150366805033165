.payment-modal-props {
  margin-top: 10vh;
}

.payment-modal-content {
  border-radius: 5px;
  width: 520px;
}

.payment-modal-header {
  width: 100%;
}

.payment-modal-body {
  padding: 0px;
  width: 520px;
  height: 362px;
}

.payment-frame-filler-footer {
  height: 37px;
  width: 100%;
  bottom: 0px;
  position: absolute;
  z-index: 1;
  background: url("https://zpay.static.zynga.com/images/pay/purchase/footer-red.png");
  background-repeat: repeat-x;
  border-radius: 0px 0px 5px 5px;
  border: 0px;
}
.payment-iframe {
  width: 0px;
  height: 0px;
  position: absolute;
  z-index: 10;
  border-radius: 0px 0px 5px 5px;
  border: 0px;
}

.payment-settings-iframe {
  padding-left: 8px;
}
