

.reward-timer-text {
  text-align: center;
  vertical-align: middle;
  line-height: 16px;
  width: 285px;
  color: #000000;
  font-size: 14px;
  position: absolute;
  z-index: 100;
  margin-top: 3px;
}

.highlighted-timer {
  font-size: 14px;
  color: white;
  font-weight: bold;
  text-shadow: 0.5px 0.5px #EABF24;
  text-align: center;
}

.hud-container {
  z-index: 1;
  position: relative;
  margin-bottom: 2px;
}

.hud-contents {
  width: 300px;
  height: 72px;
}

.claim-reward-text {
  padding: 1.2px;
  font-size: 14px;
  color: white;
  text-align: left;
  margin-top: 30px;
}

.claim-button {
  margin-top: 11px;
  width: 100px;
  height: 37px;
  font-size: 0.9rem;
  float: right;
  z-index: 1;
}

.btn.disabled, .btn:disabled {
  color: white;
  background-color: grey;
}  

.gift-box-shake {
  animation-name: shakeMe;
  animation-duration: 5s;
  animation-iteration-count: 5;
  animation-timing-function: linear;
}

.tag-icon {
  margin-right: 12px;
  z-index: -1;
  height: 30px;
  width: auto;
  float: left;
  position: relative;
}

.gift-box-icon {
  float: right;
  width: 62px;
  height: 62px;
  top: 21px;
  position: absolute;
}

.tag-text {
  text-align: center;
  vertical-align: middle;
  width: 90px;
  color: white;
  position: absolute;
  margin-top: 1px;
  font-size: 18px;
}

@keyframes shakeMe {
  2%, 18% {
    transform: translate(1px, 1px) rotate(0deg);
  }

  4%, 16% {
    transform: translate(-5px, 0px) rotate(20deg);
  }

  6%, 10%, 14% {
    transform: translate(7px, -5px) rotate(-10deg);
  }

  8%, 12% {
    transform: translate(-5px, -7px) rotate(20deg);
  }
  
  18.1% {
    ransform: translate(7px, -5px) rotate(-10deg); 
  }
}
