.login-modal-close-btn {
  position: absolute;
  z-index: 2;
  right: 0;
  margin-right: 0vw;
  margin-top: 0vw;
}

.login-modal-content {
  position: relative;
}

.login-modal-body {
  background-color: #fefefe;
  padding: 0;
}

.login-modal-form {
  padding: 2vh 2vw !important;
  font-size: 1rem;
}

.exp-coupon-image-modal {

}

.exp-coupon-text-modal {
  font-size: 1.2rem;
  color: darkviolet;
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
}

.exp-side-card-modal-content {
  position: relative;
  text-align: center;
  margin: 0;
  padding: 0;
  transform: rotate(3deg);
}


.exp-side-card-modal {
  position: absolute;
  text-align: center;
  z-index: 2;
  right: -5vw;
  top: 25vh;
  margin: 0;
  padding: 0;
  height: 100px;
  width: 100px;
}

@media screen and (max-width: 321px) {
  .login-modal-close-btn {
    margin-top: 4vh;
    color: white;
  }
}
