.modal-common-props {
  margin-top: 7vh;
  max-width: 700px; /** Override breakpoint of default 500px */
}

.modal-account-props {
  margin-top: 10vh;
}

.carousel-min-pad {
  padding: 13px;
}

.modal-close-btn {
  position: absolute;
  z-index: 2;
  right: 0;
  margin: 5px 15px 0px 0px !important;
  padding: 0px 0px 0px 0px !important;
  color: white;
}

.modal-close-btn:hover {
  color: white !important;
}

@media screen and (max-width: 321px) {
  .modal-close-btn {
    margin-top: 4vh;
    margin-left: -4vw;
    color: white;
  }
}
