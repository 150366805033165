@import url("https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css");

.fb-login-button {
  background-color: #4a6da8;
  color: white;
  font-size: 1rem;
  margin: 20px;
  margin-top: 20px;
  border-radius: 5px;
  cursor: pointer;
}

.fb-login-button-icon {
  width: 20%;
}

.form-signin-button {
  padding-left: 30px;
  padding-right: 30px;
  font-size: 60%;
}

.form-signin-button-max-width {
  padding-left: 30px;
  padding-right: 30px;
  font-size: 60%;
  width: 100%;
}



.content-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.mb-30p{
  margin-bottom: 30px !important;
}

.copyright-div-text {
  font-size: 10px !important ;
  background-color: rgba(255, 255, 255, 0.979);
  padding: 0 10px;
  font-style: normal;
}
.mt-8n{
  margin-top: -8px !important;
}

.keep-logged-in-text{
  font-size: 12px !important;
  text-indent: 5px;
  vertical-align: text-bottom;
  margin-bottom: 7px;
}
.fp-badge-custom{
  width: fit-content;
  display: block;
  cursor: pointer;
}
.button-login {
  margin: auto;
}

.login-left-pane-image {
  width: 100%;
  height: 100%;
}
#loginModalCenter .modal-dialog-centered .modal-content .login-modal-body .login-modal-close-btn{
  padding: 0px 10px;
}

.login-desc-left-pane-content {
  font-size: 1.1rem;
  font-weight: normal;
}

.login-desc-left-pane-content-img {
  width: 100%;
  padding: 0.75vw;
}

.grey-btn {
  color: #6c6c6c !important;
}

.forgot-margins {
  margin-top: 30px !important;
  margin-bottom: 40px !important;
}

.email-sent-margins {
  margin-top: 30px !important;
  margin-bottom: 150px !important;
}

.full-ht{
  height: 100% !important;
}

.tos {
  font-size: 0.75rem;
}

.small-font {
  font-size: 0.75rem;
}

.signup-image {
  width: 55%;
  padding: 0.75vw;
}

.signup-fb-container {
  background-color: #222c37;
  width: 100%;
  color: white;
}

.signup-fb-content {
  margin-top: 25%;
}

.is-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pride {
  /* background-color: transparent !important; */
  border-color: transparent !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  background-size: cover !important;
  /* background: linear-gradient(rgba(255,255,255,.1), rgba(255,255,255,.1)); */
}

.pride:not(:disabled):not(.disabled).active {
  background-blend-mode: color;
  background-color: red;
}

.error-message-container {
  background-color: #FFCDCD !important;
  color: #222222 !important;
}

.sign-in-options {
  background-color:#FFFFFF !important;
  color: #4492FF !important;
}

.no-cross-img {
  background-image: none !important;
}

@media screen and (min-width: 1440px) {
  .signup-image {
    width: 75%;
    padding: 0.75vw;
  }
}

@media screen and (max-width: 767px) {
  .signup-image {
    width: 40%;
    padding: 0.75vw;
  }
}

@media screen and (max-width: 600px) {
  .signup-image {
    width: 40%;
    padding: 0.75vw;
  }

  .signup-fb-content {
    margin-top: 5%;
  }
}
@media screen and (max-width: 321px) {
  .signup-image {
    width: 55%;
    padding: 0.75vw;
  }

  .signup-fb-content {
    margin-top: 5%;
  }
}
