@import url(https://fonts.googleapis.com/css?family=PT+Sans);
@import url(https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css);
@import url(https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css);
@import url(https://fonts.googleapis.com/css?family=PT+Sans);
.bilingual-excerpt {
  direction: rtl;
  unicode-bidi: embed;
  display: inline;
  text-align: right;
}

.progress-position {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin-right: 0;
  margin-left: 0;
  width: 100%;
  height: 3px;
}

.progress-network {
  position: relative;
  /* height: 1px; */
  display: block;
  width: 100%;
  background-color: transparent;
  border-radius: 1.5px;
  background-clip: padding-box;
  /* margin: 0.5rem 0 1rem 0; */
  overflow: hidden;
}

.progress-network .determinate {
  position: absolute;
  background-color: inherit;
  top: 0;
  bottom: 0;
  background-color: #3ba634;
  -webkit-transition: width 0.3s linear;
  transition: width 0.3s linear;
}

.progress-network .indeterminate {
  background-color: #3ba634;
}
.progress-network .indeterminate:before {
  content: "";
  position: absolute;
  background-color: inherit;
  top: 0;
  left: 0;
  bottom: 0;
  will-change: left, right;
  -webkit-animation: indeterminate 2.3s cubic-bezier(0.65, 0.815, 0.735, 0.395)
    infinite;
  animation: indeterminate 2.3s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
}
.progress-network .indeterminate:after {
  content: "";
  position: absolute;
  background-color: inherit;
  top: 0;
  left: 0;
  bottom: 0;
  will-change: left, right;
  -webkit-animation: indeterminate-short 2.1s cubic-bezier(0.165, 0.84, 0.44, 1)
    infinite;
  animation: indeterminate-short 2.1s cubic-bezier(0.165, 0.84, 0.44, 1)
    infinite;
  -webkit-animation-delay: 1.15s;
  animation-delay: 1.15s;
}

@-webkit-keyframes indeterminate {
  0% {
    left: -35%;
    right: 100%;
  }
  60% {
    left: 100%;
    right: -90%;
  }
  100% {
    left: 100%;
    right: -90%;
  }
}
@keyframes indeterminate {
  0% {
    left: -35%;
    right: 100%;
  }
  60% {
    left: 100%;
    right: -90%;
  }
  100% {
    left: 100%;
    right: -90%;
  }
}
@-webkit-keyframes indeterminate-short {
  0% {
    left: -200%;
    right: 100%;
  }
  60% {
    left: 107%;
    right: -8%;
  }
  100% {
    left: 107%;
    right: -8%;
  }
}
@keyframes indeterminate-short {
  0% {
    left: -200%;
    right: 100%;
  }
  60% {
    left: 107%;
    right: -8%;
  }
  100% {
    left: 107%;
    right: -8%;
  }
}

.notification-list-customized {
  margin-top: 2vh;
  width: 20rem;
  padding: 0.05rem 0.05rem;
  background-color: white;
  /* overflow-x: hidden; */
  max-height: 25rem;
}

.notification-scroll {
  overflow-y: scroll !important;
}

.notification-scroll::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px #222222;
  border-radius: 6px;
  background-color: #f5f5f5;
}

.notification-scroll::-webkit-scrollbar {
  width: 8px;
  background-color: #f5f5f5;
}

.notification-scroll::-webkit-scrollbar-thumb {
  border-radius: 6px;
  box-shadow: inset 0 0 6px #222222;
  background-color: #555;
}

.notification-item-customized {
  white-space: normal;
  background-color: white;
  margin: 0.2vh 0.2vw;
  border-radius: 4px;
}

.notification-item-unseen {
  background-color: rgb(223, 221, 221) !important;
}

.notification-buttons {
  -webkit-transform: scale(0.6);
          transform: scale(0.6);
}

.notification-close-override {
  position: relative !important;
  margin: 0px !important;
}

.navigation {
  position: fixed;
  top: 0;
  width: 100%;
  height: 60px;
  background: #32485d;
}
.navigation .inner-navigation {
  padding: 0;
  margin: 0;
}
.navigation .inner-navigation li {
  list-style-type: none;
}
.navigation .inner-navigation li .menu-link {
  color: #212529;
  line-height: 3.7em;
  padding: 20px 18px;
  text-decoration: none;
  -webkit-transition: background 0.5s, color 0.5s;
  transition: background 0.5s, color 0.5s;
}
.navigation .inner-navigation li .menu-link.menu-anchor {
  padding: 20px;
  margin: 0;
  background: #212529;
  color: #fff;
}
.navigation .inner-navigation li .menu-link.has-notifications {
  background: #212529;
  color: #fff;
}
.navigation .inner-navigation li .menu-link.circle {
  line-height: 3.8em;
  padding: 14px 18px;
  border-radius: 50%;
}
.navigation .inner-navigation li .menu-link.circle:hover {
  background: #212529;
  color: #fff;
}
.navigation .inner-navigation li .menu-link.square:hover {
  background: #212529;
  color: #fff;
  -webkit-transition: background 0.5s, color 0.5s;
  transition: background 0.5s, color 0.5s;
}
.dropdown-container {
  overflow-y: hidden;
  font-size: 1rem;
}
.dropdown-container.expanded .dropdown {
  -webkit-animation: fadein 0.5s;
  animation: fadein 0.5s;
  display: block;
}
.dropdown-container .dropdown {
  -webkit-animation: fadeout 0.5s;
  animation: fadeout 0.5s;
  display: none;
  position: absolute;
  width: 18rem;
  height: auto;
  max-height: 600px;
  overflow-y: hidden;
  padding: 0;
  margin: 0;
  background: #ffffff;
  margin-top: 3px;
  margin-right: -15px;
  border-top: 4px solid #212529;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  box-shadow: 2px 2px 15px -5px rgba(0, 0, 0, 0.75);
  /* &:before{
    position: absolute;
    content: ' ';
    width: 0;
    height: 0;
    top: -13px;
    right: 7px;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 10px solid $secondary-color;
 }
  */
}
.dropdown-container .dropdown .notification-group {
  border-bottom: 1px solid #e3e3e3;
  overflow: hidden;
  min-height: 65px;
}
.dropdown-container .dropdown .notification-group:last-child {
  border-bottom: 0;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}
.dropdown-container .dropdown .notification-group .notification-tab {
  padding: 0px 15px;
  min-height: 65px;
  font-size: 1rem !important;
}
.dropdown-container .dropdown .notification-group .notification-tab:hover {
  cursor: pointer;
  background: #EAEAEA;
  color: #222222;
}
.dropdown-container .dropdown .notification-group .notification-tab:hover .fa,
.dropdown-container .dropdown .notification-group .notification-tab:hover h4,
.dropdown-container
  .dropdown
  .notification-group
  .notification-tab:hover
  .label {
  color: #222222;
  display: inline-block;
}
/*.dropdown-container
  .dropdown
  .notification-group
  .notification-tab:hover
  .label {
  background: #212529;
  border-color: #212529;
}*/
.dropdown-container .dropdown .notification-group .notification-list {
  padding: 0;
  overflow-y: auto;
  height: 0px;
  max-height: 250px;
  -webkit-transition: height 0.5s;
  transition: height 0.5s;
}
.dropdown-container
  .dropdown
  .notification-group
  .notification-list
  .notification-list-item {
  padding: 0.5rem;
  border-bottom: 1px solid #e3e3e3;
}
.dropdown-container
  .dropdown
  .notification-group
  .notification-list
  .notification-list-item
  .message {
  margin: 5px 5px 10px;
}
.dropdown-container
  .dropdown
  .notification-group
  .notification-list
  .notification-list-item
  .item-footer
  a {
  color: #32485d;
  text-decoration: none;
}
.dropdown-container
  .dropdown
  .notification-group
  .notification-list
  .notification-list-item
  .item-footer
  .date {
  float: right;
}
.dropdown-container
  .dropdown
  .notification-group
  .notification-list
  .notification-list-item:nth-of-type(odd) {
  background: #e3e3e3;
}
.dropdown-container
  .dropdown
  .notification-group
  .notification-list
  .notification-list-item:hover {
  cursor: default;
}
.dropdown-container
  .dropdown
  .notification-group
  .notification-list
  .notification-list-item:last-child {
  border-bottom: 0;
}
.dropdown-container .dropdown .notification-group.expanded .notification-tab {
  background: #EAEAEA;
  color: #222222;
}

.addFriendHr {
  border-color: #EAEAEA !important;
  margin-top: 0.05rem;
  margin-bottom: 0.01rem;
}

.dropdown-container
  .dropdown
  .notification-group.expanded
  .notification-tab
  .fa,
.dropdown-container .dropdown .notification-group.expanded .notification-tab h4,
.dropdown-container
  .dropdown
  .notification-group.expanded
  .notification-tab
  .label {
  color: #222;
  display: inline-block;
}
/*.dropdown-container
  .dropdown
  .notification-group.expanded
  .notification-tab
  .label {
  background: #212529;
  border-color: #212529;
}*/
.dropdown-container .dropdown .notification-group.expanded .notification-list {
  height: 250px;
  max-height: 250px;
  -webkit-transition: height 0.5s;
  transition: height 0.5s;
}
.dropdown-container .dropdown .notification-group .fa,
.dropdown-container .dropdown .notification-group h4,
.dropdown-container .dropdown .notification-group .label {
  color: #333;
  display: inline-block;
}
.dropdown-container .dropdown .notification-group .fa {
  margin-right: 15px;
  margin-top: 25px;
}
.dropdown-container .dropdown .notification-group .label {
  float: right;
  margin-top: 20px;
  color: #32485d;
  /* border: 1px solid #32485D; */
  padding: 0px 7px;
  border-radius: 15px;
}
.right {
  float: right;
}
.left {
  float: left;
}

@media only screen and (max-width: 321px) {
  .dropdown-container .dropdown .notification-group .notification-tab h4 {
    display: none;
  }
  .dropdown-container .dropdown .notification-group .notification-tab:hover h4 {
    display: none;
  }
  .dropdown-container
    .dropdown
    .notification-group.expanded
    .notification-tab
    h4 {
    display: none;
  }
}
@media only screen and (max-width: 514px) {
  .dropdown-container .dropdown {
    width: 100%;
    margin: 0px;
    left: 0;
  }
}
@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@-webkit-keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

body {
  font-family: "PT Sans";
}

html {
  scrollbar-width: thin;
  scrollbar-color: rgb(170, 170, 170) transparent;
  scroll-behavior: smooth;
}

body::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 1px;
	background-color: transparent;
}

body::-webkit-scrollbar
{
	width: 2px;
	background-color: transparent;
}

body::-webkit-scrollbar-thumb
{
	border-radius: 2px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: rgb(170, 170, 170);
}

.hz-text-align {
  text-align: center;
}

.rounded-corners {
  border-radius: 5px;
}

.rounded-corners-top {
  border-radius: 5px 5px 0px 0px;
}

.rounded-corners-bottom {
  border-radius: 0px 0px 5px 5px;
}

.rounded-corners-top-neg {
  border-radius: -5px -5px 0px 0px;
}

.rounded-corners-bottom-neg {
  border-radius: 0px 0px -5px -5px;
}

.rounded-corners-top {
  border-radius: 5px 5px 0px 0px;
}

.rounded-corners-bottom {
  border-radius: 0px 0px 5px 5px;
}

.rounded-corners-top-neg {
  border-radius: -5px -5px 0px 0px;
}

.rounded-corners-bottom-neg {
  border-radius: 0px 0px -5px -5px;
}

.modal-head {
  background-color: #222c37;
  color: white;
}

.debugging-border {
  border: solid red 2px;
}
.debugging-border-green {
  border: solid green 2px;
}
.debugging-border-blue {
  border: solid blue 2px;
}
.debugging-border-yellow {
  border: solid yellow 2px;
}

.div-button {
  cursor: pointer;
}

.auto-margin {
  margin: auto;
}

.text-center {
  text-align: center;
}

.equally-spaced {
  margin-left: auto;
  margin-right: auto;
}

.equally-spaced-top {
  margin-top: auto;
  margin-bottom: auto;
}

.zero-pad {
  padding-left: 0px;
  padding-right: 0px;
}

.zero-pad-l {
  padding-left: 0px;
}

.zero-pad-r {
  padding-right: 0px;
}

.zero-pad-t {
  padding-top: 0px;
}

.zero-pad-b {
  padding-bottom: 0px;
}

.zero-margin-l {
  margin-left: 0px;
}

.zero-margin-r {
  margin-right: 0px !important ;
}

.zero-margin-t {
  margin-top: 0px;
}

.zero-margin-b {
  margin-bottom: 0px;
}

.text-align-l {
  text-align: left;
}

.text-align-r {
  text-align: right;
}

.soft-pad {
  padding: 5% 0px 5% 0px;
}

.transparent {
  opacity: 0;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

/* Form validation */
.inp-error {
  -webkit-animation: shake 0.2s ease-in-out 0s 2;
          animation: shake 0.2s ease-in-out 0s 2;
  box-shadow: 0 0 0.5em red;
  border-radius: 5px;
}

.notification-item {
  background: #d6d7d8;
  /* border: 1px solid; */
  /* border-color: #e5e6e9 #dfe0e4 #d0d1d5; */
  border-radius: 3px;
  padding: 12px;
  margin: 0 auto;
  height: 6rem;
  width: 100%;
  overflow: hidden;
  margin: 0.1rem 0.1rem;
}

@-webkit-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

.animated-background-notification {
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-name: placeHolderShimmer;
          animation-name: placeHolderShimmer;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
  background: #bdbebe;
  background: -webkit-gradient(linear, left top, right top, color-stop(8%, #bdbebe), color-stop(18%, #d6d7d8), color-stop(33%, #bdbebe));
  background: -webkit-linear-gradient(left, #bdbebe 8%, #d6d7d8 18%, #bdbebe 33%);
  background: linear-gradient(to right, #bdbebe 8%, #d6d7d8 18%, #bdbebe 33%);
  background-size: 800px 104px;
  height: 96px;
  position: relative;
  margin-bottom: 15px;
}

.notification-background-masker {
  background: #d6d7d8;
  position: absolute;
}

.notification-background-masker.header-top,
.notification-background-masker.header-bottom,
.notification-background-masker.subheader-bottom {
  top: 0;
  left: 40px;
  right: 0;
  height: 10px;
}

.notification-background-masker.header-left,
.notification-background-masker.subheader-left,
.notification-background-masker.header-right,
.notification-background-masker.subheader-right {
  top: 10px;
  left: 40px;
  height: 8px;
  width: 10px;
}

.notification-background-masker.header-bottom {
  top: 18px;
  height: 6px;
}

.notification-background-masker.subheader-left,
.notification-background-masker.subheader-right {
  top: 24px;
  height: 6px;
}

.notification-background-masker.header-right,
.notification-background-masker.subheader-right {
  width: auto;
  left: 300px;
  right: 0;
}

.notification-background-masker.subheader-right {
  left: 230px;
}

.notification-background-masker.subheader-bottom {
  top: 30px;
  height: 10px;
}

.notification-background-masker.content-top,
.notification-background-masker.content-second-line,
.notification-background-masker.content-third-line,
.notification-background-masker.content-second-end,
.notification-background-masker.content-third-end,
.notification-background-masker.content-first-end {
  top: 40px;
  left: 0;
  right: 0;
  height: 6px;
}

.notification-background-masker.content-top {
  height: 20px;
}

.notification-background-masker.content-first-end,
.notification-background-masker.content-second-end,
.notification-background-masker.content-third-end {
  width: auto;
  left: 380px;
  right: 0;
  top: 60px;
  height: 8px;
}

.notification-background-masker.content-second-line {
  top: 68px;
}

.notification-background-masker.content-second-end {
  left: 420px;
  top: 74px;
}

.notification-background-masker.content-third-line {
  top: 82px;
}

.notification-background-masker.content-third-end {
  left: 300px;
  top: 88px;
}

/* Green button */
.button-green {
  background-color: #3ba634;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  border-radius: 3px;
  margin-right: 0.5vw;
  margin-top: 0.3vw;
}

.button-green:hover {
  background-color: #429321;
}

.button-green-text {
  font-weight: 600;
  font-size: 1.1rem;
  color: #ffffff;
  letter-spacing: 0;
  text-shadow: 0 1px 0 #417505;
  vertical-align: -webkit-baseline-middle;
}

.button-green-text:hover {
  color: #ffffff;
}

/* Transparent white button */
.button-transparent {
  background-color: transparent;
  border-radius: 0.25rem;
  border: 0.1rem solid white;
  margin-right: 5%;
  margin-top: 0.3vw;
}

.button-transparent:hover {
  background-color: white;
}

.button-white-text {
  font-weight: 600;
  font-size: 1.1rem;
  color: #ffffff;
  letter-spacing: 0;
  text-shadow: 0 1px 0 #2a2a2a;
}

.button-white-text:hover {
  color: #2a2a2a;
}

.button-transparent-black {
  background-color: transparent;
  border-radius: 0.25rem;
  border: 0.1rem solid black;
  margin-right: 5%;
  margin-top: 0.3vw;
}

.button-transparent-black:hover {
  background-color: rgb(243, 243, 243);
}

.button-black-text {
  font-weight: 600;
  font-size: 1.1rem;
  color: black;
  letter-spacing: 0;
  text-shadow: 0 1px 0 #2a2a2a;
}

.button-black-text:hover {
  color: #222222;
}

.button-facebook {
  display: flex;
  justify-content: center;
  align-items: center;

  font-weight: 600;
  font-size: 1.1rem;
  color: white;
  letter-spacing: 0;
  text-shadow: 0 1px 0 rgba(103, 102, 103, 0.5);
  background-color: #1877f2 !important;
}

.button-facebook-round {
  width: 20%;
  display: inline;
  background-color: #1777f2;
  padding: 0.5rem 0.01rem;
  margin-left: 0.3rem;
  margin-right: 0.3rem;
  color: white;
  font-family: Helvetica, Arial, sans-serif;
  font-weight: bold;
  font-size: 0.9rem;
  border-radius: 1rem;
  cursor: pointer;
  vertical-align: middle;
}

.button-facebook-round > img {
  height: 1rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  vertical-align: middle;
  /* float: left; */
  display: inline;
}

.button-facebook-round > span {
  text-align: center;
  margin: auto;
  vertical-align: middle;
  padding-right: 0.5rem;
}

.link-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  display: inline;
  margin: 0;
  padding: 0;
  color: #007bff;
}

.button-zynga {
  display: flex;
  justify-content: center;
  align-items: center;

  font-weight: 600;
  font-size: 1.1rem;
  color: white;
  letter-spacing: 0;
  text-shadow: 0 1px 0 rgba(103, 102, 103, 0.5);
  background-color: #db0706 !important;
}

.button-logo {
  height: 3vh;
  vertical-align: middle;
  padding-left: 0.5vh;
  padding-right: 0.5vh;
}

.button-text-white {
  color: white;
  text-shadow: 0 1px 0 #2a2a2a;
  vertical-align: middle;
}

.signup-btn {
  margin-top: 10px !important;
  width: 92% !important;
}

/* Grey button */

.button-grey {
  background-color: #9a9797;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  border-radius: 3px;
  margin-right: 5%;
  margin-top: 0.3vw;
}

.button-grey:hover {
  background-color: #f3f3f3;
}

.button-grey-text {
  font-weight: 600;
  font-size: 1.1rem;
  color: white;
  letter-spacing: 0;
  text-shadow: 0 1px 0 rgba(103, 102, 103, 0.5);
}

.button-grey-text:hover {
  color: #4a4a4a;
  text-shadow: 0 1px 0 white;
}

/* Blue button */

.button-blue {
  background-color: #4579b9;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  border-radius: 3px;
  margin-right: 5%;
  margin-top: 0.3vw;
}

.button-blue:hover {
  background-color: #5298c1;
}

.button-blue-text {
  font-weight: 600;
  font-size: 1.1rem;
  color: #ffffff;
  letter-spacing: 0;
  text-shadow: 0 1px 0 #417505;
}

.button-blue-text:hover {
  color: #ffffff;
}

.restore-farm-button {
  /* add in extra class of action button */
  background-color: darkcyan;
}

@media screen and (min-width: 1440px) {
  .button-green {
    margin-top: -0.2vw;
  }

  .button-grey {
    margin-top: -0.2vw;
  }

  .button-blue {
    margin-top: -0.2vw;
  }
}

/** Mobile, iphoneX, pixel 2 */
@media screen and (max-width: 600px) {
  .button-green {
  }

  .button-grey {
  }

  .button-blue {
  }
}

/** Mobile, iphoneX, pixel 2 */
@media screen and (max-width: 321px) {
  .button-green {
    margin-right: 1.4vw;
    margin-top: 0.1vw;
  }

  .button-grey {
    margin-right: 1.4vw;
    margin-top: 0.1vw;
  }

  .button-blue {
    margin-right: 1.4vw;
    margin-top: 0.1vw;
  }

  .button-zynga {
    margin-right: 1.4vw;
    margin-top: 0.1vw;
  }

  .button-facebook {
    margin-right: 1.4vw;
    margin-top: 0.1vw;
  }
}

.menu-icon-styles {
  width: 2.6rem;
  padding: 0.2rem;
  display: inline-block;
}

.menu-wrap-text-styles {
  text-decoration: none;
  color: #222c37;
  font-size: 1.3rem;
  vertical-align: middle;
}
.input-text-below{
  font-size: 0.8rem;
  color: white !important;
}
.fs-i{
  font-style: italic !important;
}
.ai-fs{
  align-items: flex-start !important;
}
.vl-b{
  vertical-align: bottom !important;
}
.display-inline-b{
  display: inline-block !important;
}
.fb-connect-btn {
  font-size: 0.8rem;
}
.pb-5p3{
padding-bottom:5.3px !important ;
}
.mr-14{
  margin-right: 14px !important;
}
.btn-separator{
  border-right: 1px solid white;
}
.display-contents{
  display: contents !important;
}
.nav-fa-icon {
  width: 2.6rem !important;
  padding-right: 1.5rem !important;
  vertical-align: middle !important;
}
.btn-fb {
  font-weight: 600;
  color: white;
  letter-spacing: 0;
  text-shadow: 0 1px 0 rgba(103, 102, 103, 0.5);
  background-color: #4a6ea9 !important;
}

.btn-fb:hover{
  background-color: #365899 !important;
}

.btn-fb-text{
  font-size: 0.8rem;
  display: inline-block;
  vertical-align: text-bottom;
}
.fa-facebook-f.fb-hover:hover{
  color: white !important;
}
.dropdown-menu-customized {
  margin-top: 2vw;
  margin-right: -20px;
}

.external-link{
  font-size: 0.7rem !important;
  position: absolute;
  margin-left: -3px;
  color: white;
  margin-top: 5px;
}

.white-seperator{
  border-left:white 1px solid;
  height: 48px;
  vertical-align: -webkit-baseline-middle;
  margin-left: 5px;
  box-sizing: border-box;
  display:inline-block;
}

.nav-link.nav-link-style:hover > .nav-fa-icon {
  color: #3f51b5;
}

.nav-link.nav-link-style:hover {
  background-color: #EAEAEA;
}

/********************************* 
 * Desktop navigation styles
 *********************************/

.navbar-custom {
  /* padding-bottom: 2.5vw; */
  /* padding-top: 23px; */
  padding-right: 0px !important;
  margin-right: 0px !important;
}

.nav-l-icons {
  display: inline-block;
  position: relative;
  /* padding-top: 23px; */
}

.nav-r-icons {
  margin-top: auto;
  margin-bottom: auto;
}

.zynga-logo-branding {
  font-size: 3rem !important;
  left: 70px;
  bottom: 10px;
  height: 70px;
  background-size: auto 70px;
}

.exp-coupon-image {
  display: inline-block;
  height: 70px;
  width: 70px;
  position: relative;
}

.exp-coupon-text {
  display: inline-block;
  font-size: 0.7rem;
  color: yellow;
  text-align: center;
  width: 90px;
  position: relative;
}

.exp-coupon-image-right {
  display: inline-block;
  height: 70px;
  width: 70px;
  position: relative;
}

.exp-coupon-text-right {
  display: inline-block;
  font-size: 0.7rem;
  color: yellow;
  text-align: center;
  width: 90px;
  position: relative;
}

.branding-hover-hide {
  -webkit-transform: scale(0);
          transform: scale(0);
  -webkit-transition-duration: 0.4s;
          transition-duration: 0.4s;
  z-index: 2;
  display: inline-block;
}

.branding-hover-show {
  -webkit-transform: scale(1);
          transform: scale(1);
  -webkit-transition-duration: 0.4s;
          transition-duration: 0.4s;
  z-index: 3;
  display: inline-block;
}

.zynga-logo-branding-container {
  /* width: 4vw; */
  text-align: center;
  margin-top: auto;
  margin-bottom: auto;
  padding: 0.25vw;
}

.option-elements {
  margin-right: 10px;
}

.option-elements {
  margin-right: 1vw;
}

.navbar-brand {
  position: relative;
  background-color: #dc0606;
  width: 250px;
  padding-top: 20px;
}

.connectOptions {
}

.connect-user-state-loggedin {
}

.connect-user-state-loggedout {
}

.connect-user-actions-login-signup {
}

.connect-user-actions-logout {
}

.not-loggedin-welcome {
  font-weight: 400;
  font-size: 2.5vw;
  color: white;
  display: inline-block;
}

.connect-user-state-loggedin-picture {
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 25px;
  width: 40px;
  height: 40px;
}

.loggedin-options-dropdown {
  margin-top: 7%;
}

/** Localizer button */
.localizer-toggle {
  cursor: pointer;
  background-color: #4990e2;
  font-weight: 600;
  font-size: 2vw;
  color: #ffffff;
  width: 4vw;
  height: 4vw;
  border-radius: 2vw;
  text-align: center;
  padding-top: 0.9vw;

  background-position: center;
  background-size: 2vw 2vw;
  background-repeat: no-repeat;
  -webkit-animation: localizer-anim 16s infinite;
          animation: localizer-anim 16s infinite;
}

.fa:hover{
  color: #4FA035 !important;
}

/* logged in btn */
.loggedin-button {
  cursor: pointer;
  color: #ffffff;
  padding-top: 15px;
}

.nav-buttons:hover {
  background-color: #4FA035;
  color: #ffffff;
  border-radius: 10px;
}

.nav-center {
  vertical-align: middle !important;
  padding-right: 4vw;
  /* text-decoration: none !important; */
}

.tos-bottom {
  position: absolute;
  bottom: -15px;
}

.home {
  font-size: 2.5rem !important;
  padding: 0 1.1rem;
}

.community {
  font-size: 2rem !important;
  padding: 0.1rem 0.3rem;
}

.fs-inherit {
  font-size: inherit;
}

.loginSection {
  margin-top: 20px;
}

/* home btn */
.home-button {
  color: white !important;
  vertical-align: middle !important;
  z-index: 1;
  text-decoration: none !important;
}

/* Notification bell */
.notification-bell {
  color: white !important;
  vertical-align: middle !important;
  font-size: 2rem !important;
  z-index: 1;
  margin-top: 15px;
}

.notification-number {
  z-index: 2;
  vertical-align: top !important;
  margin-left: -30%;
  margin-top: 15px;
}

.no-cross-img {
  background-image: none !important;
}

@-webkit-keyframes localizer-anim {
  0% {
    background-image: none;
    color: #ffffff;
    /* -webkit-transition: 2s ease; */
    /* transition: 2s ease; */
  }
  25% {
    background-image: url("https://zlive.cdn.zynga.com/images/translations/translation_icon_100.png");
    color: transparent;
    /* -webkit-transition: 2s ease; */
    /* transition: 2s ease; */
  }
  50% {
    background-image: none;
    color: #ffffff;
    /* -webkit-transition: 2s ease; */
    /* transition: 2s ease; */
  }
  100% {
    background-image: url("https://zlive.cdn.zynga.com/images/translations/translation_icon_100.png");
    color: transparent;
    /* -webkit-transition: 2s ease; */
    /* transition: 2s ease; */
  }
}

@keyframes localizer-anim {
  0% {
    background-image: none;
    color: #ffffff;
    /* -webkit-transition: 2s ease; */
    /* transition: 2s ease; */
  }
  25% {
    background-image: url("https://zlive.cdn.zynga.com/images/translations/translation_icon_100.png");
    color: transparent;
    /* -webkit-transition: 2s ease; */
    /* transition: 2s ease; */
  }
  50% {
    background-image: none;
    color: #ffffff;
    /* -webkit-transition: 2s ease; */
    /* transition: 2s ease; */
  }
  100% {
    background-image: url("https://zlive.cdn.zynga.com/images/translations/translation_icon_100.png");
    color: transparent;
    /* -webkit-transition: 2s ease; */
    /* transition: 2s ease; */
  }
}

@media screen and (min-width: 1441px) {
  /* .menu-icon-styles {
    width: 4vw;
    padding: 0.85vw 0.5vw 0.5vw 0.5vw;
    display: inline-block;
  } */

  /* .menu-wrap-text-styles {
    font-size: 1.2vw;
  } */

  .navbar-custom {
    padding-bottom: 0vw;
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.14);
  }

  .nav-r-icons {
    margin-top: auto;
    margin-bottom: auto;
    /* padding-right: 0px; */
  }

  .zynga-logo-branding-container {
    /* width: 4vw; */
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .zynga-logo-branding {
    margin-top: auto;
    margin-bottom: auto;
  }

  .localizer-toggle {
    font-size: 1.8vw;
    width: 3vw;
    height: 3vw;
    padding-top: 0.5vw;
    border-radius: 1.5vw;

    background-position: center;
    background-size: 2vw 2vw;
    background-repeat: no-repeat;
    -webkit-animation: localizer-anim 16s infinite;
            animation: localizer-anim 16s infinite;
    -webkit-transition: 1s ease;
    transition: 1s ease;
  }

  .not-loggedin-welcome {
    font-size: 2vw;
    line-height: 2.25vw;
  }

  .option-elements {
    padding: -0.9vw;
  }
}

/** Mobile, iphoneX, pixel 2 */
@media screen and (max-width: 600px) {
  /* .menu-icon-styles {
    width: 7vw;
    padding: 0.5vh 0.5vw 0.5vh 0.5vw;
    display: inline-block;
  } */

  /* .menu-wrap-text-styles {
    font-size: 3vw !important;
  } */

  .zynga-logo-branding {
    /* width: 8vw;
    height: 8vw; */
    /* margin-left: 4vw; */
  }

  .localizer-toggle {
    font-size: 4vw;
    width: 8vw;
    height: 8vw;
    padding-top: 2vw;
    border-radius: 4vw;

    background-position: center;
    background-size: 6.5vw 6.5vw;
    background-repeat: no-repeat;
    -webkit-animation: localizer-anim 16s infinite;
            animation: localizer-anim 16s infinite;
    -webkit-transition: 1s ease;
    transition: 1s ease;
  }

  .not-loggedin-welcome {
    font-size: 4vw;
    line-height: 4vw;
    display: none;
  }

  .option-elements {
    padding: -0.1vw;
  }

  /* Notification bell */
  .notification-bell {
    font-size: 1.5rem !important;
  }

  .tos-bottom {
    position: static;
    bottom: -15px;
  }

}

.wrapper {
  /* background: wheat; */
  background: transparent;
  width: 15rem;
  display: flex;
  flex-direction: column;
}
.wrapper-cell {
  display: flex;
  margin-bottom: 0.25rem;
  margin-top: 0.25rem;
}
@-webkit-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
.animated-background,
.image,
.text-line-short,
.text-line-med,
.text-line-long {
  -webkit-animation-duration: 1.25s;
          animation-duration: 1.25s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-name: placeHolderShimmer;
          animation-name: placeHolderShimmer;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
  background: transparent;
  background: -webkit-gradient(
    linear,
    left top, right top,
    color-stop(8%, transparent),
    color-stop(18%, rgb(57, 66, 77)),
    color-stop(33%, transparent)
  );
  background: -webkit-linear-gradient(
    left,
    transparent 8%,
    rgb(57, 66, 77) 18%,
    transparent 33%
  );
  background: linear-gradient(
    to right,
    transparent 8%,
    rgb(57, 66, 77) 18%,
    transparent 33%
  );
  background-size: 800px 104px;
  height: 96px;
  position: relative;
}
.image {
  height: 2rem;
  width: 2rem;
}
.text {
  margin-left: 1rem;
}
.text-line-short {
  height: 0.5rem;
  width: 5rem;
  margin: 0.25rem 0;
  border-radius: 0.25rem;
}
.text-line-med {
  height: 0.5rem;
  width: 8rem;
  margin: 0.25rem 0;
  border-radius: 0.25rem;
}
.text-line-long {
  height: 0.5rem;
  width: 12rem;
  margin: 0.25rem 0;
  border-radius: 0.25rem;
}



.reward-timer-text {
  text-align: center;
  vertical-align: middle;
  line-height: 16px;
  width: 285px;
  color: #000000;
  font-size: 14px;
  position: absolute;
  z-index: 100;
  margin-top: 3px;
}

.highlighted-timer {
  font-size: 14px;
  color: white;
  font-weight: bold;
  text-shadow: 0.5px 0.5px #EABF24;
  text-align: center;
}

.hud-container {
  z-index: 1;
  position: relative;
  margin-bottom: 2px;
}

.hud-contents {
  width: 300px;
  height: 72px;
}

.claim-reward-text {
  padding: 1.2px;
  font-size: 14px;
  color: white;
  text-align: left;
  margin-top: 30px;
}

.claim-button {
  margin-top: 11px;
  width: 100px;
  height: 37px;
  font-size: 0.9rem;
  float: right;
  z-index: 1;
}

.btn.disabled, .btn:disabled {
  color: white;
  background-color: grey;
}  

.gift-box-shake {
  -webkit-animation-name: shakeMe;
          animation-name: shakeMe;
  -webkit-animation-duration: 5s;
          animation-duration: 5s;
  -webkit-animation-iteration-count: 5;
          animation-iteration-count: 5;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
}

.tag-icon {
  margin-right: 12px;
  z-index: -1;
  height: 30px;
  width: auto;
  float: left;
  position: relative;
}

.gift-box-icon {
  float: right;
  width: 62px;
  height: 62px;
  top: 21px;
  position: absolute;
}

.tag-text {
  text-align: center;
  vertical-align: middle;
  width: 90px;
  color: white;
  position: absolute;
  margin-top: 1px;
  font-size: 18px;
}

@-webkit-keyframes shakeMe {
  2%, 18% {
    -webkit-transform: translate(1px, 1px) rotate(0deg);
            transform: translate(1px, 1px) rotate(0deg);
  }

  4%, 16% {
    -webkit-transform: translate(-5px, 0px) rotate(20deg);
            transform: translate(-5px, 0px) rotate(20deg);
  }

  6%, 10%, 14% {
    -webkit-transform: translate(7px, -5px) rotate(-10deg);
            transform: translate(7px, -5px) rotate(-10deg);
  }

  8%, 12% {
    -webkit-transform: translate(-5px, -7px) rotate(20deg);
            transform: translate(-5px, -7px) rotate(20deg);
  }
  
  18.1% {
    ransform: translate(7px, -5px) rotate(-10deg); 
  }
}

@keyframes shakeMe {
  2%, 18% {
    -webkit-transform: translate(1px, 1px) rotate(0deg);
            transform: translate(1px, 1px) rotate(0deg);
  }

  4%, 16% {
    -webkit-transform: translate(-5px, 0px) rotate(20deg);
            transform: translate(-5px, 0px) rotate(20deg);
  }

  6%, 10%, 14% {
    -webkit-transform: translate(7px, -5px) rotate(-10deg);
            transform: translate(7px, -5px) rotate(-10deg);
  }

  8%, 12% {
    -webkit-transform: translate(-5px, -7px) rotate(20deg);
            transform: translate(-5px, -7px) rotate(20deg);
  }
  
  18.1% {
    ransform: translate(7px, -5px) rotate(-10deg); 
  }
}

.fb-login-button {
  background-color: #4a6da8;
  color: white;
  font-size: 1rem;
  margin: 20px;
  margin-top: 20px;
  border-radius: 5px;
  cursor: pointer;
}

.fb-login-button-icon {
  width: 20%;
}

.form-signin-button {
  padding-left: 30px;
  padding-right: 30px;
  font-size: 60%;
}

.form-signin-button-max-width {
  padding-left: 30px;
  padding-right: 30px;
  font-size: 60%;
  width: 100%;
}



.content-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.mb-30p{
  margin-bottom: 30px !important;
}

.copyright-div-text {
  font-size: 10px !important ;
  background-color: rgba(255, 255, 255, 0.979);
  padding: 0 10px;
  font-style: normal;
}
.mt-8n{
  margin-top: -8px !important;
}

.keep-logged-in-text{
  font-size: 12px !important;
  text-indent: 5px;
  vertical-align: text-bottom;
  margin-bottom: 7px;
}
.fp-badge-custom{
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: block;
  cursor: pointer;
}
.button-login {
  margin: auto;
}

.login-left-pane-image {
  width: 100%;
  height: 100%;
}
#loginModalCenter .modal-dialog-centered .modal-content .login-modal-body .login-modal-close-btn{
  padding: 0px 10px;
}

.login-desc-left-pane-content {
  font-size: 1.1rem;
  font-weight: normal;
}

.login-desc-left-pane-content-img {
  width: 100%;
  padding: 0.75vw;
}

.grey-btn {
  color: #6c6c6c !important;
}

.forgot-margins {
  margin-top: 30px !important;
  margin-bottom: 40px !important;
}

.email-sent-margins {
  margin-top: 30px !important;
  margin-bottom: 150px !important;
}

.full-ht{
  height: 100% !important;
}

.tos {
  font-size: 0.75rem;
}

.small-font {
  font-size: 0.75rem;
}

.signup-image {
  width: 55%;
  padding: 0.75vw;
}

.signup-fb-container {
  background-color: #222c37;
  width: 100%;
  color: white;
}

.signup-fb-content {
  margin-top: 25%;
}

.is-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pride {
  /* background-color: transparent !important; */
  border-color: transparent !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  background-size: cover !important;
  /* background: linear-gradient(rgba(255,255,255,.1), rgba(255,255,255,.1)); */
}

.pride:not(:disabled):not(.disabled).active {
  background-blend-mode: color;
  background-color: red;
}

.error-message-container {
  background-color: #FFCDCD !important;
  color: #222222 !important;
}

.sign-in-options {
  background-color:#FFFFFF !important;
  color: #4492FF !important;
}

.no-cross-img {
  background-image: none !important;
}

@media screen and (min-width: 1440px) {
  .signup-image {
    width: 75%;
    padding: 0.75vw;
  }
}

@media screen and (max-width: 767px) {
  .signup-image {
    width: 40%;
    padding: 0.75vw;
  }
}

@media screen and (max-width: 600px) {
  .signup-image {
    width: 40%;
    padding: 0.75vw;
  }

  .signup-fb-content {
    margin-top: 5%;
  }
}
@media screen and (max-width: 321px) {
  .signup-image {
    width: 55%;
    padding: 0.75vw;
  }

  .signup-fb-content {
    margin-top: 5%;
  }
}

.login-modal-close-btn {
  position: absolute;
  z-index: 2;
  right: 0;
  margin-right: 0vw;
  margin-top: 0vw;
}

.login-modal-content {
  position: relative;
}

.login-modal-body {
  background-color: #fefefe;
  padding: 0;
}

.login-modal-form {
  padding: 2vh 2vw !important;
  font-size: 1rem;
}

.exp-coupon-image-modal {

}

.exp-coupon-text-modal {
  font-size: 1.2rem;
  color: darkviolet;
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
}

.exp-side-card-modal-content {
  position: relative;
  text-align: center;
  margin: 0;
  padding: 0;
  -webkit-transform: rotate(3deg);
          transform: rotate(3deg);
}


.exp-side-card-modal {
  position: absolute;
  text-align: center;
  z-index: 2;
  right: -5vw;
  top: 25vh;
  margin: 0;
  padding: 0;
  height: 100px;
  width: 100px;
}

@media screen and (max-width: 321px) {
  .login-modal-close-btn {
    margin-top: 4vh;
    color: white;
  }
}

.modal-common-props {
  margin-top: 7vh;
  max-width: 700px; /** Override breakpoint of default 500px */
}

.modal-account-props {
  margin-top: 10vh;
}

.carousel-min-pad {
  padding: 13px;
}

.modal-close-btn {
  position: absolute;
  z-index: 2;
  right: 0;
  margin: 5px 15px 0px 0px !important;
  padding: 0px 0px 0px 0px !important;
  color: white;
}

.modal-close-btn:hover {
  color: white !important;
}

@media screen and (max-width: 321px) {
  .modal-close-btn {
    margin-top: 4vh;
    margin-left: -4vw;
    color: white;
  }
}

/* Footer */
section {
  padding: 60px 0;
}

.opt-out-col {
  text-align: center;
  color: grey;
  margin-bottom: 3rem;
}
.opt-out {
  /* margin: auto; */
  font-size: 1rem;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  margin: 2rem;
  padding: 0.25rem;
  border-radius: 0.5rem;
}

.opt-out:hover {
  cursor: pointer;
  background-color: grey;
  color: black;
  -webkit-transition: 1.5s;
  transition: 1.5s;
}

section .section-title {
  text-align: center;
  color: #222c37;
  margin-bottom: 50px;
  text-transform: uppercase;
}
#footer {
  margin-top: 0px;
  background: #222c37 !important;
}
#footer h5 {
  padding-left: 10px;
  border-left: 3px solid #eeeeee;
  padding-bottom: 6px;
  margin-bottom: 20px;
  color: #ffffff;
}
#footer a {
  color: #ffffff;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}
#footer ul.social li {
  padding: 3px 0;
}
#footer ul.social li a i {
  margin-right: 5px;
  font-size: 25px;
  -webkit-transition: 0.5s all ease;
  transition: 0.5s all ease;
}
#footer ul.social li:hover a i {
  font-size: 30px;
  margin-top: -10px;
}
#footer ul.social li a,
#footer ul.quick-links li a {
  color: #ffffff;
}
#footer ul.social li a:hover {
  color: #eeeeee;
}
#footer ul.quick-links li {
  padding: 3px 0;
  -webkit-transition: 0.5s all ease;
  transition: 0.5s all ease;
}
#footer ul.quick-links li:hover {
  padding: 3px 0;
  margin-left: 5px;
  font-weight: 700;
}
#footer ul.quick-links li a i {
  margin-right: 5px;
}
#footer ul.quick-links li:hover a i {
  font-weight: 700;
}

@media (max-width: 767px) {
  #footer h5 {
    padding-left: 0;
    border-left: transparent;
    padding-bottom: 0px;
    margin-bottom: 10px;
  }
}

#branding-section {
  text-align: center !important;
  background: #1d252d;
  color: rgba(255, 255, 255, 0.4);
  padding: 24px 0;
  text-transform: uppercase;
  font-size: 13px;
  display: block;
  margin: 0px;
}

.footer-branding-logo-col {
  margin: auto;
}

.footer-branding-logo {
  width: 4rem;
  font-size: 0.12em;
  display: inline-block;
  border-radius: 2px;
}

.nav-link-styles {
  font-size: 0.65em;
  font-style: normal;
}

.nav-link-styles:hover {
  -webkit-text-decoration: underline white !important;
          text-decoration: underline white !important;
}

.footer-social-icon {
  width: 35px;
  margin: 8px;
  filter: gray; /* IE 9 */
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
}

.footer-social-icon:hover {
  -webkit-filter: grayscale(0);
  filter: grayscale(0);
  -webkit-animation: shake 0.82s;
          animation: shake 0.82s;
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
}

.footer-row {
  margin: 0px;
}
@-webkit-keyframes shake {
  10% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }

  20%,
  80% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  30%,
  50%,
  70% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
  }

  40%,
  60% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}
@keyframes shake {
  10% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }

  20%,
  80% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  30%,
  50%,
  70% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
  }

  40%,
  60% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}

.farm2-pageroot {
  color: inherit;
  font-family: "PT Sans";
  font-weight: 400;
  line-height: 1.2;
}

.farm2-pageroot-font {
  font-size: 2.25rem;
}

.routerContainer {
  background-color: white;
  min-height: 900px;
}

#contentSection {
  background-color: white;
}

.payment-modal-props {
  margin-top: 10vh;
}

.payment-modal-content {
  border-radius: 5px;
  width: 520px;
}

.payment-modal-header {
  width: 100%;
}

.payment-modal-body {
  padding: 0px;
  width: 520px;
  height: 362px;
}

.payment-frame-filler-footer {
  height: 37px;
  width: 100%;
  bottom: 0px;
  position: absolute;
  z-index: 1;
  background: url("https://zpay.static.zynga.com/images/pay/purchase/footer-red.png");
  background-repeat: repeat-x;
  border-radius: 0px 0px 5px 5px;
  border: 0px;
}
.payment-iframe {
  width: 0px;
  height: 0px;
  position: absolute;
  z-index: 10;
  border-radius: 0px 0px 5px 5px;
  border: 0px;
}

.payment-settings-iframe {
  padding-left: 8px;
}

.gdpr-modal-content {
  border-radius: 5px;
  margin: auto;
}

.gdpr-modal-body {
  padding: 0px;
  width: 98%;
  margin: auto;
}

.gdpr-frame-filler-footer {
  height: 37px;
  width: 100%;
  bottom: 0px;
  position: absolute;
  z-index: 1;
  background: url("https://zpay.static.zynga.com/images/pay/purchase/footer-red.png");
  background-repeat: repeat-x;
  border-radius: 0px 0px 5px 5px;
  border: 0px;
}

.gdpr-frame {
  padding: 1vw;
}

.gdpr-body-text {
  font-size: 1.3rem;
}

h1 {
  font-size: 20px;
  text-align: center;
  margin: 20px 0 20px;
}
h1 small {
  display: block;
  font-size: 15px;
  padding-top: 8px;
  color: gray;
}

@media screen and (max-width: 600px) {
}

@media screen and (max-width: 321px) {
}

.survey-dialog {
  background-color: #222c37;
  color: white;
  font-size: 1rem !important;
}

.survey-close-btn {
  position: absolute;
  z-index: 2;
  right: 0;
  margin-right: 0.5vw;
  margin-top: 0.5vw;
}

#beta-carousel {
  margin-top: 1rem;
  padding: 2rem;
  border: 1px grey solid;
  border-radius: 2px;
}

.survey-radio-options {
  margin-left: 0.9rem;
  vertical-align: middle;
  margin-top: auto;
  margin-bottom: auto;
  cursor: pointer;
}

.survey-input-hole {
  cursor: pointer;
}

.scroll_helper_common {
  position: fixed;
  right: 1rem;
  width: 2rem;
  height: 2rem;
  z-index: 99999;
  background-color: white;
  border-radius: 0.5rem;
  cursor: pointer;
}

.scroll_helper_up {
  bottom: 3.1rem;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  cursor: pointer;
}

.scroll_helper_down {
  bottom: 1rem;
  cursor: pointer;
}

.scroll_helper_up:hover {
  background-color: rgba(64, 133, 112, 0.541);
}

.scroll_helper_down:hover {
  background-color: rgba(64, 133, 112, 0.541);
}

.info-modal-body {
    padding: 0;
}
  
.info-header {
    font-size: 2vw;
}

.reward-info-text {
    color: white;
    display: block;
    text-align: center;
}

.info-container {
    z-index: 100;
    position: absolute;
    margin-left: 55px;
    margin-top: 15px;
}

.info-subheader {
    font-size: 1.1vw;
    margin-top: 10px;
    color: darkgray;
}

.info-timer {
    color: #000000;
    font-size: 1.2vw;
    margin-top: 30px;
}

.info-close {
    font-size: 2.0rem;
    margin-right: 0px !important;
    opacity: 1;
}

.reward-card {
    height: 150px;
    width: 150px
}

.rewards-scrollable-container {
  margin-top: 30px;
  max-height: 530px;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: thin;
  scrollbar-color: rgb(170, 170, 170) transparent;
}

.rewards-scrollable-container::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 1px;
  background-color: transparent;
}

.rewards-scrollable-container::-webkit-scrollbar {
  width: 5px;
  background-color: rgb(45, 44, 44) lightgray;
}

.rewards-scrollable-container::-webkit-scrollbar-thumb {
  border-radius: 2px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color:  rgb(99, 97, 97);
}

.grid-container {
    display: grid;
    grid-template-columns: 150px 150px 150px 150px;
    grid-gap: 5px;
    padding: 10px 25px;
  }
  
.grid-container > div {
    padding: 0px 0;
}

.reward-slot-bg {
    position: relative;
    color: black;
}

.reward-slot-contents {
    position: absolute;
    top: 8%;
    left: 18%;
    font-family: 'PT Sans';
}

.reward-slot-text {
    font-size: 18px;
    text-align: center;
    -webkit-margin-after: 0px !important;
            margin-block-end: 0px !important;
}

.v2-btn {
    width: 300px;
    margin-left: 180px;
    margin-top: 26px;
}

.v1-btn {
    width: 300px;
    margin-left: 180px;
    margin-top: 14vh;
}

.v1-container .row {
    margin-top:20px;
    margin-bottom: 20px;
    text-align:center;
}
.v1-container .row img {
    margin:0 20px;
    height: 200px;
    width: 200px;
}

.img-container {
    position: relative;
}

.img-animate-1 {
    -webkit-animation: test 1s ease-in forwards;
            animation: test 1s ease-in forwards;
}

@-webkit-keyframes test {
    from { 
        -webkit-transform: translate(200px,100px) scale(0); 
                transform: translate(200px,100px) scale(0);
    }
    to   { 
        -webkit-transform: translate(0px,0px) scale(1); 
                transform: translate(0px,0px) scale(1);
    }
}

@keyframes test {
    from { 
        -webkit-transform: translate(200px,100px) scale(0); 
                transform: translate(200px,100px) scale(0);
    }
    to   { 
        -webkit-transform: translate(0px,0px) scale(1); 
                transform: translate(0px,0px) scale(1);
    }
}

.img-animate-2 {
    opacity: 0;
    -webkit-animation: test2 1s ease-in forwards 1s;
            animation: test2 1s ease-in forwards 1s;
}

@-webkit-keyframes test2 {
    from { 
        opacity: 0;
        -webkit-transform: translate(200px,300px) scale(0);
                transform: translate(200px,300px) scale(0);
    }
    to   { 
        opacity: 1;
        -webkit-transform: translate(0px,0px) scale(1);
                transform: translate(0px,0px) scale(1);
    }
}

@keyframes test2 {
    from { 
        opacity: 0;
        -webkit-transform: translate(200px,300px) scale(0);
                transform: translate(200px,300px) scale(0);
    }
    to   { 
        opacity: 1;
        -webkit-transform: translate(0px,0px) scale(1);
                transform: translate(0px,0px) scale(1);
    }
}

.img-animate-3 {
    -webkit-animation: test3 1s ease-in forwards 2s;
            animation: test3 1s ease-in forwards 2s;
    opacity: 0;
}

@-webkit-keyframes test3 {
    from { 
        opacity: 0;
        -webkit-transform: translate(100px,400px) scale(0);
                transform: translate(100px,400px) scale(0);
    }
    to   { 
        opacity: 1;
        -webkit-transform: translate(0px,0px) scale(1);
                transform: translate(0px,0px) scale(1);
    }
}

@keyframes test3 {
    from { 
        opacity: 0;
        -webkit-transform: translate(100px,400px) scale(0);
                transform: translate(100px,400px) scale(0);
    }
    to   { 
        opacity: 1;
        -webkit-transform: translate(0px,0px) scale(1);
                transform: translate(0px,0px) scale(1);
    }
}

.img-animate-4 {
    -webkit-animation: test4 1s ease-in forwards 3s;
            animation: test4 1s ease-in forwards 3s;
    opacity: 0;
}

@-webkit-keyframes test4 {
    from { 
        opacity: 0;
        -webkit-transform: translate(-50px,300px) scale(0);
                transform: translate(-50px,300px) scale(0);
    }
    to   { 
        opacity: 1;
        -webkit-transform: translate(0px,0px) scale(1);
                transform: translate(0px,0px) scale(1);
    }
}

@keyframes test4 {
    from { 
        opacity: 0;
        -webkit-transform: translate(-50px,300px) scale(0);
                transform: translate(-50px,300px) scale(0);
    }
    to   { 
        opacity: 1;
        -webkit-transform: translate(0px,0px) scale(1);
                transform: translate(0px,0px) scale(1);
    }
}

.img-animate-5 {
    -webkit-animation: test5 1s ease-in forwards 4s;
            animation: test5 1s ease-in forwards 4s;
    opacity: 0;
}

@-webkit-keyframes test5 {
    from { 
        opacity: 0;
        -webkit-transform: translate(-200px,160px) scale(0);
                transform: translate(-200px,160px) scale(0);
    }
    to   { 
        opacity: 1;
        -webkit-transform: translate(0px,0px) scale(1);
                transform: translate(0px,0px) scale(1);
    }
}

@keyframes test5 {
    from { 
        opacity: 0;
        -webkit-transform: translate(-200px,160px) scale(0);
                transform: translate(-200px,160px) scale(0);
    }
    to   { 
        opacity: 1;
        -webkit-transform: translate(0px,0px) scale(1);
                transform: translate(0px,0px) scale(1);
    }
}

.check-mark {
    position: absolute;
    height: 60px;
    left: 20px;
    bottom: 30px;
}

.reward-name {
    color: white;
    font-size: 14px;
    margin-top: -28px;
    background-color: rgba(140,99,25,0.6);
    text-align: center;
    z-index: 100;
    position: relative;
    width: 140px;
    margin-left: 4px;
}

.reward-modal-body {
    height: 460px;
    width: 860px;
    background-position: center;
    background-size: auto;
    background-repeat: no-repeat;
                       
}

.reward-button {
    margin-top: -25px;
    width: 260px;
    margin-left: 280px;
    height: 37px;
    font-size: 1.2rem;
    box-shadow: 2px 3px 10px #383838;
}

.reward-container {
    z-index: 100;
    position: absolute;
}

.header-text {
    color: white;
    display: block;
    text-align: center;
    font-size: 30px;
}

.reward-content-custom {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.2);
    border-radius: .3rem;
    outline: 0;
}

.reward-subheader {
    font-size: 22px;
    margin-top: 5px;
    color: darkgray;
    display: block;
    text-align: center;
}

@-webkit-keyframes rewardImg {
    from { opacity: 0; }
    to   { opacity: 1; }
}

@keyframes rewardImg {
    from { opacity: 0; }
    to   { opacity: 1; }
}

.reward-img {
    -webkit-animation: rewardImg 1s ease-in forwards 0.3s;
            animation: rewardImg 1s ease-in forwards 0.3s;
    opacity: 0;
}

.footer-text {
    color: white;
    font-size: 16px;
    margin-top: 10px;
    background-color: rgba(119,52,5,0.6);
    text-align: center;
}

.toc-text {
  font-family: "PT Sans";
  font-size: 1.25rem;
  text-align: left;
}

.toc-header-text {
  font-family: "PT Sans";
  font-style: normal;
  text-align: center;
  left: 80px;
}

.toc-header-bg {
  background-color: rgb(34, 34, 34);
  height: 62px;
  padding: 0rem;
  border-top-right-radius: 0rem;
}

.modal-title {
  line-height: 2;
}

.zynga-logo-col {
  padding-left: 11px;
}

/*
.zynga-logo-icon {
  width: 4rem;
  height: 68px;
}
*/

.account_verification_accept {
  display: block;
  margin: auto;
}

.center-text {
  text-align: center;
}

.account-verification-bg {
  max-width: 120%;
  min-width: 100%;
  min-height: 110vh;
  opacity: 0.2;
}

.toc_body_text {
  font-size: 15px;
  text-align: center;
}

.toc-links {
  text-align: center;
  font-size: 15px;
}

.toc-links > ul {
  display: inline-block;
  text-align: left;
}

#verify-tos-carousel{
  font-size: 15px;
}

.email-verify-modal-close-btn {
  position: absolute;
  z-index: 2;
  right: 0;
  margin-right: 0vw;
  margin-top: 0vw;
}

.grey-btn {
  color: #f5f6f7 !important;
}
