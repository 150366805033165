.gdpr-modal-content {
  border-radius: 5px;
  margin: auto;
}

.gdpr-modal-body {
  padding: 0px;
  width: 98%;
  margin: auto;
}

.gdpr-frame-filler-footer {
  height: 37px;
  width: 100%;
  bottom: 0px;
  position: absolute;
  z-index: 1;
  background: url("https://zpay.static.zynga.com/images/pay/purchase/footer-red.png");
  background-repeat: repeat-x;
  border-radius: 0px 0px 5px 5px;
  border: 0px;
}

.gdpr-frame {
  padding: 1vw;
}

.gdpr-body-text {
  font-size: 1.3rem;
}

h1 {
  font-size: 20px;
  text-align: center;
  margin: 20px 0 20px;
}
h1 small {
  display: block;
  font-size: 15px;
  padding-top: 8px;
  color: gray;
}

@media screen and (max-width: 600px) {
}

@media screen and (max-width: 321px) {
}
