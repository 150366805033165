@import url("https://fonts.googleapis.com/css?family=PT+Sans");

.toc-text {
  font-family: "PT Sans";
  font-size: 1.25rem;
  text-align: left;
}

.toc-header-text {
  font-family: "PT Sans";
  font-style: normal;
  text-align: center;
  left: 80px;
}

.toc-header-bg {
  background-color: rgb(34, 34, 34);
  height: 62px;
  padding: 0rem;
  border-top-right-radius: 0rem;
}

.modal-title {
  line-height: 2;
}

.zynga-logo-col {
  padding-left: 11px;
}

/*
.zynga-logo-icon {
  width: 4rem;
  height: 68px;
}
*/

.account_verification_accept {
  display: block;
  margin: auto;
}

.center-text {
  text-align: center;
}

.account-verification-bg {
  max-width: 120%;
  min-width: 100%;
  min-height: 110vh;
  opacity: 0.2;
}

.toc_body_text {
  font-size: 15px;
  text-align: center;
}

.toc-links {
  text-align: center;
  font-size: 15px;
}

.toc-links > ul {
  display: inline-block;
  text-align: left;
}

#verify-tos-carousel{
  font-size: 15px;
}

.email-verify-modal-close-btn {
  position: absolute;
  z-index: 2;
  right: 0;
  margin-right: 0vw;
  margin-top: 0vw;
}

.grey-btn {
  color: #f5f6f7 !important;
}