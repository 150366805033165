
.reward-modal-body {
    height: 460px;
    width: 860px;
    background-position: center;
    background-size: auto;
    background-repeat: no-repeat;
                       
}

.reward-button {
    margin-top: -25px;
    width: 260px;
    margin-left: 280px;
    height: 37px;
    font-size: 1.2rem;
    box-shadow: 2px 3px 10px #383838;
}

.reward-container {
    z-index: 100;
    position: absolute;
}

.header-text {
    color: white;
    display: block;
    text-align: center;
    font-size: 30px;
}

.reward-content-custom {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.2);
    border-radius: .3rem;
    outline: 0;
}

.reward-subheader {
    font-size: 22px;
    margin-top: 5px;
    color: darkgray;
    display: block;
    text-align: center;
}

@keyframes rewardImg {
    from { opacity: 0; }
    to   { opacity: 1; }
}

.reward-img {
    animation: rewardImg 1s ease-in forwards 0.3s;
    opacity: 0;
}

.footer-text {
    color: white;
    font-size: 16px;
    margin-top: 10px;
    background-color: rgba(119,52,5,0.6);
    text-align: center;
}
