.notification-list-customized {
  margin-top: 2vh;
  width: 20rem;
  padding: 0.05rem 0.05rem;
  background-color: white;
  /* overflow-x: hidden; */
  max-height: 25rem;
}

.notification-scroll {
  overflow-y: scroll !important;
}

.notification-scroll::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 3px #222222;
  box-shadow: inset 0 0 6px #222222;
  border-radius: 6px;
  background-color: #f5f5f5;
}

.notification-scroll::-webkit-scrollbar {
  width: 8px;
  background-color: #f5f5f5;
}

.notification-scroll::-webkit-scrollbar-thumb {
  border-radius: 6px;
  -webkit-box-shadow: inset 0 0 3px #222222;
  box-shadow: inset 0 0 6px #222222;
  background-color: #555;
}

.notification-item-customized {
  white-space: normal;
  background-color: white;
  margin: 0.2vh 0.2vw;
  border-radius: 4px;
}

.notification-item-unseen {
  background-color: rgb(223, 221, 221) !important;
}

.notification-buttons {
  transform: scale(0.6);
}

.notification-close-override {
  position: relative !important;
  margin: 0px !important;
}

.navigation {
  position: fixed;
  top: 0;
  width: 100%;
  height: 60px;
  background: #32485d;
}
.navigation .inner-navigation {
  padding: 0;
  margin: 0;
}
.navigation .inner-navigation li {
  list-style-type: none;
}
.navigation .inner-navigation li .menu-link {
  color: #212529;
  line-height: 3.7em;
  padding: 20px 18px;
  text-decoration: none;
  transition: background 0.5s, color 0.5s;
}
.navigation .inner-navigation li .menu-link.menu-anchor {
  padding: 20px;
  margin: 0;
  background: #212529;
  color: #fff;
}
.navigation .inner-navigation li .menu-link.has-notifications {
  background: #212529;
  color: #fff;
}
.navigation .inner-navigation li .menu-link.circle {
  line-height: 3.8em;
  padding: 14px 18px;
  border-radius: 50%;
}
.navigation .inner-navigation li .menu-link.circle:hover {
  background: #212529;
  color: #fff;
}
.navigation .inner-navigation li .menu-link.square:hover {
  background: #212529;
  color: #fff;
  transition: background 0.5s, color 0.5s;
}
.dropdown-container {
  overflow-y: hidden;
  font-size: 1rem;
}
.dropdown-container.expanded .dropdown {
  -webkit-animation: fadein 0.5s;
  -moz-animation: fadein 0.5s;
  -ms-animation: fadein 0.5s;
  -o-animation: fadein 0.5s;
  animation: fadein 0.5s;
  display: block;
}
.dropdown-container .dropdown {
  -webkit-animation: fadeout 0.5s;
  -moz-animation: fadeout 0.5s;
  -ms-animation: fadeout 0.5s;
  -o-animation: fadeout 0.5s;
  animation: fadeout 0.5s;
  display: none;
  position: absolute;
  width: 18rem;
  height: auto;
  max-height: 600px;
  overflow-y: hidden;
  padding: 0;
  margin: 0;
  background: #ffffff;
  margin-top: 3px;
  margin-right: -15px;
  border-top: 4px solid #212529;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  -webkit-box-shadow: 2px 2px 15px -5px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 2px 2px 15px -5px rgba(0, 0, 0, 0.75);
  box-shadow: 2px 2px 15px -5px rgba(0, 0, 0, 0.75);
  /* &:before{
    position: absolute;
    content: ' ';
    width: 0;
    height: 0;
    top: -13px;
    right: 7px;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 10px solid $secondary-color;
 }
  */
}
.dropdown-container .dropdown .notification-group {
  border-bottom: 1px solid #e3e3e3;
  overflow: hidden;
  min-height: 65px;
}
.dropdown-container .dropdown .notification-group:last-child {
  border-bottom: 0;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}
.dropdown-container .dropdown .notification-group .notification-tab {
  padding: 0px 15px;
  min-height: 65px;
  font-size: 1rem !important;
}
.dropdown-container .dropdown .notification-group .notification-tab:hover {
  cursor: pointer;
  background: #EAEAEA;
  color: #222222;
}
.dropdown-container .dropdown .notification-group .notification-tab:hover .fa,
.dropdown-container .dropdown .notification-group .notification-tab:hover h4,
.dropdown-container
  .dropdown
  .notification-group
  .notification-tab:hover
  .label {
  color: #222222;
  display: inline-block;
}
/*.dropdown-container
  .dropdown
  .notification-group
  .notification-tab:hover
  .label {
  background: #212529;
  border-color: #212529;
}*/
.dropdown-container .dropdown .notification-group .notification-list {
  padding: 0;
  overflow-y: auto;
  height: 0px;
  max-height: 250px;
  transition: height 0.5s;
}
.dropdown-container
  .dropdown
  .notification-group
  .notification-list
  .notification-list-item {
  padding: 0.5rem;
  border-bottom: 1px solid #e3e3e3;
}
.dropdown-container
  .dropdown
  .notification-group
  .notification-list
  .notification-list-item
  .message {
  margin: 5px 5px 10px;
}
.dropdown-container
  .dropdown
  .notification-group
  .notification-list
  .notification-list-item
  .item-footer
  a {
  color: #32485d;
  text-decoration: none;
}
.dropdown-container
  .dropdown
  .notification-group
  .notification-list
  .notification-list-item
  .item-footer
  .date {
  float: right;
}
.dropdown-container
  .dropdown
  .notification-group
  .notification-list
  .notification-list-item:nth-of-type(odd) {
  background: #e3e3e3;
}
.dropdown-container
  .dropdown
  .notification-group
  .notification-list
  .notification-list-item:hover {
  cursor: default;
}
.dropdown-container
  .dropdown
  .notification-group
  .notification-list
  .notification-list-item:last-child {
  border-bottom: 0;
}
.dropdown-container .dropdown .notification-group.expanded .notification-tab {
  background: #EAEAEA;
  color: #222222;
}

.addFriendHr {
  border-color: #EAEAEA !important;
  margin-top: 0.05rem;
  margin-bottom: 0.01rem;
}

.dropdown-container
  .dropdown
  .notification-group.expanded
  .notification-tab
  .fa,
.dropdown-container .dropdown .notification-group.expanded .notification-tab h4,
.dropdown-container
  .dropdown
  .notification-group.expanded
  .notification-tab
  .label {
  color: #222;
  display: inline-block;
}
/*.dropdown-container
  .dropdown
  .notification-group.expanded
  .notification-tab
  .label {
  background: #212529;
  border-color: #212529;
}*/
.dropdown-container .dropdown .notification-group.expanded .notification-list {
  height: 250px;
  max-height: 250px;
  transition: height 0.5s;
}
.dropdown-container .dropdown .notification-group .fa,
.dropdown-container .dropdown .notification-group h4,
.dropdown-container .dropdown .notification-group .label {
  color: #333;
  display: inline-block;
}
.dropdown-container .dropdown .notification-group .fa {
  margin-right: 15px;
  margin-top: 25px;
}
.dropdown-container .dropdown .notification-group .label {
  float: right;
  margin-top: 20px;
  color: #32485d;
  /* border: 1px solid #32485D; */
  padding: 0px 7px;
  border-radius: 15px;
}
.right {
  float: right;
}
.left {
  float: left;
}

@media only screen and (max-width: 321px) {
  .dropdown-container .dropdown .notification-group .notification-tab h4 {
    display: none;
  }
  .dropdown-container .dropdown .notification-group .notification-tab:hover h4 {
    display: none;
  }
  .dropdown-container
    .dropdown
    .notification-group.expanded
    .notification-tab
    h4 {
    display: none;
  }
}
@media only screen and (max-width: 514px) {
  .dropdown-container .dropdown {
    width: 100%;
    margin: 0px;
    left: 0;
  }
}
@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-o-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@-moz-keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@-webkit-keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@-ms-keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@-o-keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
